
import moment from 'moment';

import { Nullable } from '../../types';
import {
    InstrumentType,
    Currency,
    InstrumentSubType,
    SecurityStatus
} from '../../enums';

import { Security } from '../../interfaces';

import { baseDateFormat, baseTimeFormat } from '../../core/constants';
import { formatToThousandsSeparator, isNil } from '../../utils';

import { setDayCountConventionDataValue } from './day-count-convention.worker';
import { setQuotationConventionDataValue } from './quotation-convention.worker';
import { setPeriodicityCodeLabelDataValue } from './periodicity-code.worker';
import { setMarketRuleDataValue } from './market-rule.worker';

import { SecurityMarketDataDTO, SecurityMarketDataValue, setSecurityMarketDataValue } from './security-market-data.worker';
import { SecurityMarketRealtimeDTO, SecurityMarketRealtimeDataValue, setSecurityMarketRealtimeDataValue } from './security-market-realtime.worker';
import { DocumentDTO, DocumentDataValue, setDocumentDataValue } from './document.worker';
import { HomeMarketDTO, HomeMarketDataValue, setHomeMarketDataValue } from './home-market.worker';
import { TradingVenueDTO, TradingVenueDataValue, setTradingVenueDataValue } from './trading-venue.worker';
import { TierDTO, TierDataValue, setTierDataValue } from './tier.worker';
import { BrokerDTO, BrokerDataValue, setBrokerDataValue } from './broker.workers';
import { setTranslatableFieldDataValue, TranslatableFieldDTO, TranslatableFieldDataValue } from './translatable-field.worker';

type SecurityExludedKeys = 'homeMarket' | 'tradingVenue' | 'marketData' | 'marketRealtime' | 'documents' | 'issuers' | 'brokers' | 'instrumentTypeTranslatableField' | 'instrumentSubtypeTranslatableField';

export type SecurityDataValue = { [key in keyof Required<Pick<Security, Exclude<keyof Security, SecurityExludedKeys | 'tags' | 'lgxDisplay'>>>]: string } & {
    instrumentTypeTranslatableField: TranslatableFieldDataValue;
    instrumentSubtypeTranslatableField: TranslatableFieldDataValue;
    homeMarket: HomeMarketDataValue;
    tradingVenue: TradingVenueDataValue;
    brokers: BrokerDataValue[];
    marketData: SecurityMarketDataValue;
    marketRealtime: SecurityMarketRealtimeDataValue;
    documents: DocumentDataValue[];
    issuers: TierDataValue[];
    tags: string[];
    lgxDisplay: boolean;
};

export type SecurityDTO = Partial<Nullable<Readonly<Pick<Security, Exclude<keyof Security, SecurityExludedKeys>> & {
    instrumentTypeTranslatableField: TranslatableFieldDTO;
    instrumentSubtypeTranslatableField: TranslatableFieldDTO;
    homeMarket: HomeMarketDTO;
    tradingVenue: TradingVenueDTO;
    brokers: BrokerDTO[];
    marketData: SecurityMarketDataDTO;
    marketRealtime: SecurityMarketRealtimeDTO;
    documents: DocumentDTO[];
    issuers: TierDTO[];
}>>>;

export const setSecurityStatus = (ss: SecurityStatus, tags: string[]): string => {
    switch (ss) {
        case SecurityStatus.ADMI: return 'Tradable';
        case SecurityStatus.NEGO: return 'Tradable';
        case SecurityStatus.SUSP: return 'Suspended';
        case SecurityStatus.RETR: return tags.includes('ATT') ? 'Removed' : 'Delisted';
        default: return 'n/a';
    }
}

export const setTags = (tags: string[]): string[] => {
    let ftags: string | string[] = [];

    ftags = tags.filter((tag: string): boolean => {
        return !['ATT', 'RBDL_GRBD', 'RBDL_SOCI', 'RBDL_SUST'].includes(tag);
    });

    if (tags.includes('RBDL_GRBD')) {
        ftags = ['Green', ...ftags];
    }
    else if (tags.includes('RBDL_SOCI')) {
        ftags = ['Social', ...ftags];
    }
    else if (tags.includes('RBDL_SUST')) {
        ftags = ['Sustainable', ...ftags];
    }

    if (tags.includes('ATT')) {
        ftags = [...ftags, 'ATT'];
    }

    return ftags;
}

export const setIntrumentTypeValue = (instrumentType?: InstrumentType | null | undefined): string => {
    return 'n/a';
};

export const setSuspensionReason = (r: string): string => {
    switch (r) {
        case 'ARET':
            return 'Pending withdrawal';
        case 'CHMC':
            return 'Change of trading group & pricing method (clean to dirty)';
        case 'CPAY':
            return 'Listing suspended at the stock exchange in the Issuer\'s home country';
        case 'CSSF':
            return 'At the request of CSSF';
        case 'DEM':
            return 'Request by the company';
        case 'DEMA':
            return 'Request by the company';
        case 'EVOD':
            return 'Event of default';
        case 'FACT':
            return 'Non payment of invoices';
        case 'LIQU':
            return 'Liquidation';
        case 'NRRB':
            return 'Non compliant with stock exchange regulations';
        case 'OTHR':
            return 'Other (refer to the comment)';
        case 'REGL':
            return 'Compliance with stock market regulations';
        case 'LEVE':
            return 'Suspension lifted';
        default:
            return 'n/a';
    }
};
