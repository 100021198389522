import moment from "moment-timezone";
import {useCallback, useEffect, useState} from "react";
import {OAMResultsDeposit} from "../../useFilters";
import {GET_OAM_SUBMISSION_DETAIL} from "../../../../../graphql";
import {luxseGatewayGraphqlClient} from "../../../../../luxse-gateway-graphql-client";

interface OAMRepositoryDetailDTO {
    submitted?: OAMDocument[]
    events?: OAMEvent[]
}

export interface OAMDetails {
    documents: OAMDocument[]
    events: OAMEvent[]
}

export interface OAMDocument {
    category: string;
    name: string
    obsolete: boolean
    publishDate: moment.Moment; // FIXME GJO number (timestamp) ?
    size: number;
    url: string;
}

export interface OAMEvent {
    eventCode: string;
    dtPubli: moment.Moment; // FIXME GJO number (timestamp) ?
    attachedDocs?: OAMDocument[];
    oldValueStart?: string; // Date
    newValueStart?: string; // Date
}

export default function useDetails(deposit: OAMResultsDeposit) {
    const [open, setOpen] = useState<boolean>(false);
    const [details, setDetails] = useState<OAMDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const client = luxseGatewayGraphqlClient;

    const onToggle = useCallback(() => {
        setOpen(open => !open);
    }, []);

    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            try {
                const results = await client.query({
                    query: GET_OAM_SUBMISSION_DETAIL,
                    variables: {
                        submissionId: deposit.idSoumis,
                    },
                });
                const data = results.data.oamSubmissionDetail;
                setDetails({
                    documents: data.documents.map((doc: any) => ({
                        category: doc.category,
                        name: doc.fileName,
                        obsolete: doc.obsolete,
                        publishDate: moment(doc.publicationDate),
                        size: doc.size,
                        url: doc.url,
                    })),
                    events: data.events.map((event: any) => ({
                        eventCode: event.eventCode,
                        dtPubli: moment(event.publicationDate),
                        attachedDocs: event.attachedDocuments?.map((doc: any) => ({
                            category: doc.category,
                            name: doc.fileName,
                            obsolete: doc.obsolete,
                            publishDate: moment(doc.publicationDate),
                            size: doc.size,
                            url: doc.url,
                        })),
                        oldStartValue: event.oldStartValue,
                        newStartValue: event.newStartValue,
                    })),
                });
            } catch (error) {
                setDetails({documents: [], events: []});
                console.error("Failed to get submission details", error);
            } finally {
                setLoading(false);
            }
        };

        if (open && !details) {
            fetchDetails();
        }
    }, [open, details, deposit.idSoumis, client]);

    return {
        open,
        onToggle,
        details,
        loading,
    };
}
