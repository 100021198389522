import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import hexa from '../ListingProcess/hexa.svg';
import { renderLink } from '../../utils/helper';

/** @jsx jsx */









export interface BenefitsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const BenefitsComponent: React.FunctionComponent<BenefitsComponentProps> = (
  props: BenefitsComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const buttonLabel: string = getFieldValue<string>(fields, 'buttonLabel', '');
  const buttonLink: string = getFieldValue<string>(fields, 'buttonLink', '');
  const icon1: string = getFieldValue<string>(fields, 'icon1', '');
  const icon2: string = getFieldValue<string>(fields, 'icon2', '');
  const icon3: string = getFieldValue<string>(fields, 'icon3', '');
  const btnIcon: string = getFieldValue<string>(fields, 'btnIcon', '');
  const greyBackground: number = getFieldValue<number>(fields, 'greyBackground', 0);
  const redTitle: boolean = getFieldValue<boolean>(fields, 'redTitle', false);
  const leftAlign: boolean = getFieldValue<boolean>(fields, 'leftAlign', false);

  const style = {
    parentContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 80px;
      @media (max-width: ${breakpointMax}) {
        padding: 16px;
        padding-top: 48px;
      }
    `,
    container: css`
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 60px auto;
      justify-content: space-between;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
        margin-bottom: 24px;
      }
    `,
    leftPart: css`
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    `,
    leftPartCard: css`
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    `,
    cardTitle: css`
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      color: #cb1234;
      margin-bottom: 28px;
    `,
    title: css`
      font-weight: 800;
      font-size: 48px;
      line-height: 62px;
      color: #354450;
      margin-bottom: 24px;
      text-align: center;
      width: 100%;
      margin-top: 32px;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: ${leftAlign ? 'flex-start' : 'center'};
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        margin: 0;
      }
    `,
    contentTop: css`
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #354450;
    `,
    contentBottom: css`
      margin-top: 40px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: #354450;
    `,
    cardContent: css`
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      width: 100%;
      max-width: 375px;
      @media (max-width: ${breakpointMax}) {
        max-width: 100%;
        padding: 0;
      }
    `,
    iconCard: css`
      color: #cb1234;
      img {
        width: 44px;
        height: 48px;
      }
      margin-bottom: 16px;
      @media (max-width: ${breakpointMax}) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 32px;
        img {
          width: 110px;
          height: 110px;
        }
        svg {
          margin-left: 40px;
        }
      }
    `,
    iconCardSvg: css`
      position: absolute;
      margin-top: -36px;
      margin-left: 16px;
      @media (max-width: ${breakpointMax}) {
        margin: 0;
      }
    `,
    titleCard: css`
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: ${redTitle ? '#CB1234' : '#425563'};
      margin-bottom: 16px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
      }
    `,
    textCard: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #354450;
      @media (max-width: ${breakpointMax}) {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.01em;
        margin-bottom: 40px;
      }
    `,
    actions: css`
      width: 100%;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 64px;
      display: flex;
    `,
  };
  return (
    <div css={style.parentContainer} style={{ backgroundColor: greyBackground ? '#f0f5f5' : 'transparent' }}>
      <div css={style.title}>
        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
      </div>
      <div css={style.container}>
        {new Array(8).fill(0).map((arr: any, index: number) => {
          if (fields && fields[`title${index + 1}`] && (fields && (fields[`title${index + 1}`] as any)).value) {
            return (
              <div css={style.cardContent}>
                <div css={style.iconCard}>
                  <img src={renderLink(hexa)} alt={"image"} />
                  <div css={style.iconCardSvg}>
                    <Icon icon={(fields && fields[`icon${index + 1}`] && (fields[`icon${index + 1}`] as any)).value} />
                  </div>
                </div>
                <div css={style.titleCard}>
                  <Text field={fields[`title${index + 1}`] as SitecoreGenericTextField} />
                </div>
                <div css={style.textCard}>
                  <RichText field={fields[`text${index + 1}`] as SitecoreGenericTextField} />
                </div>
              </div>
            );
          }
        })}
      </div>
      {buttonLabel && (
        <div css={style.actions}>
          <Button
            label={buttonLabel}
            onClick={() => (buttonLink ? (window.location.href = buttonLink) : console.debug)}
            iconRight={(btnIcon as any) || false}
          />
        </div>
      )}
    </div>
  );
};
