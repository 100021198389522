
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {ResourcesHeader} from "../ResourcesHeader";
import Loader from "../../Loader/loader.component";
import {fetchData, getDataWithChild} from "../../../../utils/helper";
import {EventItemRow} from "./EventItemRow";
import {
    resourceContent,
    rowContainer,
    rowLabel,
    empty,
    tabsContainer,
    headerDouble,
    headerDoubleRight, headerDoubleLeft, rootPage, childContainer
} from "../shared.style";
import { Tabs, Tab } from '@bdl-cmn-shared-packages-npm/design-system';
import {EventComponent} from "../../Event/event.component";
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { getCurrentMoment, parseToMoment } from '../../../../utils/date';

export const ResourcesEvents = (props: any) => {
    const { fields, isH1 } = props;
    const [events, setEvents] = React.useState<any>(null);
    const [isLoadingEvents, setIsLoadingEvents] = React.useState<boolean>(true);

    const title: string = getFieldValue<string>(fields, 'title', '');
    const description: string = getFieldValue<string>(fields, 'description', '');
    const pastLabel: string = getFieldValue<string>(fields, 'past events label', '');
    const nextLabel: string = getFieldValue<string>(fields, 'next events label', '');


    const getDataFromItemId = async(id: string) => {
        try {
            const request = await fetchData(id)
            const data = await getDataWithChild(request, ['Speakers','Types']);
            dissociateEvents(data);
        } catch(err) {
            console.error(err);
        }
    }

    const tabs = [nextLabel, pastLabel];
    const [eventType, setEvenType] = React.useState<string>(tabs[0]);

    const dissociateEvents = (data: any) => {
        const past: any = [];
        const future: any = [];
        const now = getCurrentMoment();

        data.forEach((d: any) => {
            const tmpPast: any = { label: d.label, events: d.children.filter((event: any) => parseToMoment(event['End date'])?.isBefore(now))}
            const tmpFuture: any = { label: d.label, events: d.children.filter((event: any) => parseToMoment(event['End date'])?.isAfter(now))}
            if (tmpPast.events.length > 0){
                past.push(tmpPast)
            }
            if (tmpFuture.events.length > 0){
                future.push(tmpFuture)
            }
        });
        setEvents({[pastLabel]: past, [nextLabel]: future});
        setIsLoadingEvents(false);
    }

    React.useEffect(() => {
        getDataFromItemId('3FE49801-613A-4AD0-B8C6-83B906ABFBD7');
    }, []);

    return (
        <>
            <div css={rootPage}>
                <div css={headerDouble}>
                    <div css={headerDoubleLeft}>
                        <ResourcesHeader
                            title={title}
                            description={description}
                            isH1={isH1}
                        />
                    </div>
                    {
                        /*
                        <div css={headerDoubleRight}>
                            <EventComponent nextEvent={true} />
                        </div>
                         */
                    }
                </div>
            </div>

            <div css={tabsContainer}>

            {
                <Tabs tabPosition={"center"} padding={"1em 2em"}>
                    {
                        tabs && tabs.map((tab: string) => (
                            <Tab label={tab} onTabChange={() => setEvenType(tab)} selected={eventType === tab}>
                                <Loader loading={isLoadingEvents} centered css={{minHeight: 400}}>
                                    <div css={resourceContent('column')}>
                                        {
                                            (events && events[tab] || []).length > 0 ? events[tab].map((data: any, index: number) => (
                                                <div css={rowContainer(index % 2 == 0 ? 'transparent' : '#F6F8F9')}>
                                                    <div css={childContainer}>
                                                        <div css={rootPage}>
                                                            <div css={rowLabel}>
                                                                {
                                                                    data.label
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            data && data.events && data.events.map((child: any, index: number) => (
                                                                <EventItemRow data={child} key={`letter-key-${index}`} index={index} />
                                                            ))
                                                        }
                                                        </div>
                                                </div>
                                            )) : (
                                                <div css={empty}>
                                                    The search did not return any results
                                                </div>
                                            )
                                        }
                                        </div>
                                </Loader>
                            </Tab>
                        ))
                    }
                </Tabs>
            }
            </div>
        </>
    );
}
