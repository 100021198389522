import gql from "graphql-tag";

export const priceInfoFragment = gql`
  fragment priceInfoFragment on PriceInformation {
    amount
    currency
    date
    marker
    volume
    dayVolume
    changeAbsolute
    changePercent
    yieldToMaturity
  } 
`;

export const securityMarketRealtimeFragment = gql`
  fragment securityMarketRealtimeFragment on SecurityMarketRealtime {
    spreadAbsolute
    spreadRelative
    bestOrders {
      bid {
        number
        quantity
        price
        type
      }
      ask {
        number
        quantity
        price
        type
      }
    }
  } 
`;

export const securityMarketSummaryFragment = gql`
  ${priceInfoFragment}
  fragment securityMarketSummaryFragment on SecurityMarketData {
    tradeIndicator
    minimumAmountTrade
    tradingLot
    lastPrice {
      ...priceInfoFragment
    }
    previousMonthLastPrice {
      ...priceInfoFragment
    }
    dailyChange
    dayVolume
    dailyMinimum {
      ...priceInfoFragment
    }
    dailyMaximum {
      ...priceInfoFragment
    }
    yearLowestPrice {
      ...priceInfoFragment
    }
    yearHighestPrice {
      ...priceInfoFragment
    }
    yieldToMaturity
    yearToDatePercent
    yearToDateAbsolute
    yearToDatePercent
    previousDayChangeAbsolute
    previousDayChangePercent
    previousClosingPrice {
      ...priceInfoFragment
    }
  } 
`;

export const securityMarketPriceFragment = gql`
  ${priceInfoFragment}
  fragment securityMarketPriceFragment on SecurityMarketData {
    pricesHistory {
      ...priceInfoFragment
    }
  }
`;

export const securityMarketFragment = gql`
  ${priceInfoFragment}
  fragment securityMarketFragment on SecurityMarketData {
    mnemoCode
    tradingLot
    tradingCode
    tradingTypeCode
    tradingGroupCode
    tradeIndicator
    tickSizeID
    cotaGroupIndicator
    cotaGroupGarIndicator
    minimumAmountTrade
    lastPrice {
      ...priceInfoFragment
    }
    openingPrice {
      ...priceInfoFragment
    }
    previousMonthLastPrice {
      ...priceInfoFragment
    }
    dailyChange
    thresholdsLowerFloor
    thresholdsUpperFloor
    dayVolume
    dailyMinimum {
      ...priceInfoFragment
    }
    dailyMaximum {
      ...priceInfoFragment
    }
    monthLowestPrice {
      ...priceInfoFragment
    }
    monthHighestPrice {
      ...priceInfoFragment
    }
    yearLowestPrice {
      ...priceInfoFragment
    }
    yearHighestPrice {
      ...priceInfoFragment
    }
    yieldToMaturity
    yearToDatePercent
    yearToDateAbsolute
    yearToDatePercent
    previousDayChangeAbsolute
    previousDayChangePercent
    previousClosingPrice {
      ...priceInfoFragment
    }
    intradayPrices {
      ...priceInfoFragment
    }
    marketMembers {
      libele
      type
    }
    primeLiquidityProviderTier {
      id
      name
    }
    pricesHistory {
      ...priceInfoFragment
    }
  } 
`;