/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useMemo } from "react";

import { Collapse } from "react-collapse";
import { DownloadDocument } from "../../UI/list/document/row";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { OAMResultsDeposit } from "../useFilters";
import RoundTag from "../../RoundTag/roundTag.component";
import { formatDate } from "../../../../utils/date";
import { isCancelled } from "./utils";
import useDetails from "./deposit-row/useDetails";

export interface DepositRowProps {
  deposit: OAMResultsDeposit;
}

export const DepositCard = ({ deposit }: DepositRowProps) => {
  const { open, onToggle, details } = useDetails(deposit);

  const initials = useMemo(
    () =>
      deposit.libTypeDepo
        .split(" ")
        .slice(0, 2)
        .map((w) => w[0].toUpperCase())
        .join(""),
    [deposit.libTypeDepo]
  );
  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #d9dee2;
        border-radius: 8px;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
        margin-bottom: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-bottom: solid 1px #d9dee2;
          padding: 16px;
          flex-wrap: wrap;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 8px;
          `}
        >
          <RoundTag
            borderSize={2}
            textColor="#425563"
            small={true}
            padding={true}
          >
            {initials}
          </RoundTag>
        </div>

        <div
          css={css`
            font-weight: 800;
            font-size: 20px;
            line-height: 26px;
            color: #253845;
          `}
        >
          {deposit.libTypeDepo}
        </div>
      </div>

      <div
        css={css`
          border-bottom: solid 1px #d9dee2;
          display: flex;
        `}
      >
        <div
          css={css`
            width: 50%;
            border-right: solid 1px #d9dee2;
            padding: 16px;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            ID
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {deposit.idSoumis}
          </div>
        </div>
        <div
          css={css`
            width: 50%;
            padding: 16px;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Published
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            <div
              css={css`
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                color: #22aa5f;
                border: 1px solid #d9dee2;
                border-radius: 4px;
              `}
            >
              {" "}
              {!isCancelled(deposit) ? (
                <Icon icon="check" />
              ) : (
                <span
                  css={css`
                    color: #f18700;
                    font-size: 1.5rem;
                  `}
                >
                  -
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        css={css`
          padding: 16px;
          border-bottom: solid 1px #d9dee2;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #425563;
          `}
        >
          Issuer
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #54666f;
            margin-top: 8px;
          `}
        >
          {deposit.libLgTiersDecla}
        </div>
      </div>
      <div
        css={css`
          padding: 16px;
          border-bottom: solid 1px #d9dee2;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #425563;
          `}
        >
          Publication
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #54666f;
            margin-top: 8px;
          `}
        >
          {formatDate(deposit.dtPubli, "DD/MM/YYYY HH:mm:ss")}
        </div>
      </div>
      <div
        css={css`
          padding: 16px;
          border-bottom: solid 1px #d9dee2;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #425563;
          `}
        >
          Reference date / period
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #54666f;
            margin-top: 8px;
          `}
        >
          {formatDate(deposit.dtDebPerRef)}&nbsp;-&nbsp;
          {formatDate(deposit.dtFinPerRef)}
        </div>
      </div>

      <div
        css={css`
          padding: 16px;
          display: flex;
          background: #d3f2e1;
          color: #54666f;
        `}
      >
        <div
          css={css`
            width: 90%;
          `}
        >
          Download documents
        </div>
        <div
          css={css`
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              color: #22aa5f !important;
            }
          `}
          onClick={() => onToggle()}
        >
          <Icon icon={open ? "chevron-up" : "chevron-down"} />
        </div>
      </div>
      <Collapse isOpened={open}>
        {details?.documents.map((document) => (
          <div
            css={css`
              padding: 16px;
              display: flex;
              background: #e8faf0;
              border-top: 1px solid #22aa5f;
              color: #54666f;
              flex-direction: column;
              border-width: 1px 0px 0px 5px;
              border-style: solid;
              border-color: #22aa5f;
              padding-left: 32px;
            `}
          >
            <div>
              <Icon icon="file-pdf" />
            </div>

            <div
              css={css`
                margin-top: 8px;
              `}
            >
              {document.name}
            </div>
            <div
              css={[
                css`
                  margin-top: 8px;
                `,
              ]}
            >
              <span>{document.size}&nbsp;Kb</span>
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <DownloadDocument
                documentUrl={document.url}
                documentTypes={[document.category]}
                showDownload={true}
                showPreview={true}
              />
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};
