
export enum LGXEligibleCategories {
    RENEWABLE_ENERGY_GREEN = 'Renewable Energy',
    POLLUTION_PREVENTION_GREEN = 'Pollution Prevention',
    BIODIVERSITY_CONSERVATION_GREEN = 'Biodiversity Conservation',
    SUSTAINABLE_WATER_MANAGEMENT_GREEN = 'Sustainable Water Management',
    ECOEFFICIENT_PRODUCTS_GREEN = 'Eco efficient Products',
    ENERGY_EFFICIENCY_GREEN = 'Energy Efficiency',
    SUSTAINABLE_MANAGEMENT_GREEN = 'Sustainable Management',
    CLEAN_TRANSPORTATION_GREEN = 'Clean Transportation',
    CLIMATE_CHANGE_ADAPTATION_GREEN = 'Climate Change Adaptation',
    GREEN_BUILDINGS_GREEN = 'Green Buildings',
    AFFORDABLE_INFRASTRUCTURE_SOCIAL = 'Affordable Infrastructure',
    ESSENTIAL_SERVICES_SOCIAL = 'Essential Services',
    AFFORDABLE_HOUSING_SOCIAL = 'Affordable Housing',
    EMPLOYMENT_GENERATION_SOCIAL = 'Employment Generation',
    FOOD_SECURITY_SOCIAL = 'Food Security',
    SOCIOECONOMIC_ADV_SOCIAL = 'Socioeconomic ADV',
    // ENERGY_SAVING_PBOC ='ENERGY_SAVING_PBOC',
    // POLLUTION_PREVENTION_AND_CONTROL_PBOC ='POLLUTION_PREVENTION_AND_CONTROL_PBOC',
    // RESOURCE_CONSERVATION_AND_RECYCLING_PBOC ='RESOURCE_CONSERVATION_AND_RECYCLING_PBOC',
    // // CLEAN_TRANSPORT_PBOC ='CLEAN_TRANSPORT_PBOC',
    // CLEAN_ENERGY_PBOC ='CLEAN_ENERGY_PBOC',
    // ECOPROTECTION_AND_CLIMATE_ADAPTATION_PBOC ='ECOPROTECTION_AND_CLIMATE_ADAPTATION_PBOC',
    // USEFUL_AND_AFFORDABLE_INFRASTRUCTURE_SUSTAINABLE ='USEFUL_AND_AFFORDABLE_INFRASTRUCTURE_SUSTAINABLE',
    // FINANCIAL_SECTORDEV_SUSTAINABLE ='FINANCIAL_SECTORDEV_SUSTAINABLE',
    // CLIMATE_CHANGE_MITIGATION_SUSTAINABLE ='CLIMATE_CHANGE_MITIGATION_SUSTAINABLE',
    // CLIMATE_SMART_AGRICULTURE_SUSTAINABLE ='CLIMATE_SMART_AGRICULTURE_SUSTAINABLE',
    ICT_CBI = 'ICT CBI',
    BUILDINGS_CBI = 'Buildings CBI',
    // CIRCULAR_ECONOMY_NDRC ='CIRCULAR_ECONOMY_NDRC',
    // CLEAN_AND_EFFICIENT_USE_OF_ENERGY_NDRC ='CLEAN_AND_EFFICIENT_USE_OF_ENERGY_NDRC',
    // ECOLOGICAL_AGRICULTURE_AND_FORESTRY_NDRC ='ECOLOGICAL_AGRICULTURE_AND_FORESTRY_NDRC',
    // ECOLOGICAL_CIVILISATION_DEMONSTRATION_PROJECTS_NDRC ='ECOLOGICAL_CIVILISATION_DEMONSTRATION_PROJECTS_NDRC',
    ENERGY_CBI = 'Energy CBI',
    // ENERGY_SAVING_AND_ENVIRONMENTAL_PROTECTION_INDUSTRY_NDRC ='ENERGY_SAVING_AND_ENVIRONMENTAL_PROTECTION_INDUSTRY_NDRC',
    // GREEN_URBANISATION_ENERGY_SAVING_NDRC ='GREEN_URBANISATION_ENERGY_SAVING_NDRC',
    // GREEN_URBANISATION_TRANSPORT_NDRC ='GREEN_URBANISATION_TRANSPORT_NDRC',
    INDUSTRY_CBI = 'Industry CBI',
    LAND_USE_MARINE_RESOURCES_CBI = 'Land use marine resources CBI',
    // LOW_CARBON_DEMONSTRATION_NDRC ='LOW_CARBON_DEMONSTRATION_NDRC',
    // LOW_CARBON_INDUSTRY_NDRC ='LOW_CARBON_INDUSTRY_NDRC',
    // NEW_ENERGY_NDRC ='NEW_ENERGY_NDRC',
    OTHER_ELIGIBLE_CATEGORIES = 'Other eligible categories',
    // POLLUTION_PREVENTION_AND_CONTROL_NDRC ='POLLUTION_PREVENTION_AND_CONTROL_NDRC',
    // TECHNOLOGY_IMPROVEMENT_NDRC ='TECHNOLOGY_IMPROVEMENT_NDRC',
    TRANSPORT_CBI = 'Transport CBI',
    WASTE_POLLUTION_CONTROL_CBI = 'Waste pollution control CBI',
    WATER_CBI = 'Water CBI',
    // WATER_SAVING_AND_UNCONVENTIONAL_WATER_USE_NDRC ='WATER_SAVING_AND_UNCONVENTIONAL_WATER_USE_NDRC',
    PRODUCTION_TECHNOLOGIES_AND_PROCESSES = 'Production technologies and processes',
}

export function lgxEligibleCategoriesLabel(value: string): string {
    if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(LGXEligibleCategories).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(LGXEligibleCategories)[index] : value;
	}catch (e){
		console.error("Failed to get LGXEligibleCategories label for '" + value + "'", e);
	}
		
	return value;
}