import * as React from "react";

import { css, jsx } from "@emotion/react";

import { Collapse } from "react-collapse";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./sustainableTabs.style";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import Utils from "../../utils/utils";
import { SEARCH_LGX_DATA_COUNTER, SEARCH_LGX_SECURITIES_WITH_FILTERS } from "../../../graphql/queries/securities.queries";
import { counter } from "@fortawesome/fontawesome-svg-core";
import { LuxseSearchSecurityFilters } from "../../../sitecore-components/SustainableTabsComponent";

/** @jsx jsx */


export interface Row {
  label: string;
  action: () => void;
}
export interface Items {
  label: string;
  value: string;
  description?: string;
  action?: string;
  data?: Items[];
}

export interface SustainableTabsProps {
  label?: string;
  letter?: string;
  total?: number;
  data?: any;
  children?: any;
  securityData?: any;
  lgxData?: LuxseSearchSecurityFilters;
  rows?: Row[];
  index: number;
  elements?: any[];
}
export interface ParentRowProps {
  child?: Items[];
  label: string;
  value: string;
  isFirst?: boolean;
  isLast?: boolean;
  index: string;
  letter?: string;
  lgxData?: LuxseSearchSecurityFilters;
  total?: number;
  parentIndex: number;
}

const renderData = (data: LuxseSearchSecurityFilters, parentIndex: number, index: number) => {
  if (parentIndex === 0) {
    const target = data?.filters?.lgxSustainableBonds;
    console.log('target', data, target)
    if (index === 0) {
      return target?.find((t: any) => t.name === "GRBD")?.count
    }
    if (index === 1) {
      return target?.find((t: any) => t.name === "SOCI")?.count
    }
    if (index === 2) {
      return target?.find((t: any) => t.name === "SUST")?.count
    }
    if (index === 3) {
      return target?.find((t: any) => t.name === "SLB")?.count
    }
  }
  if (parentIndex === 1) {
    const target = data?.filters?.lgxSustainableFunds;
    if (index === 0) {
      return target?.find((t: any) => t.name === "ESG")?.count
    }
    if (index === 1) {
      return target?.find((t: any) => t.name === "IMPACT")?.count
    }
  }
  return ""
}
const ParentRow: React.FunctionComponent<ParentRowProps> = ({
  child,
  label,
  value,
  isFirst,
  isLast,
  index,
  letter,
  total,
  lgxData,
  parentIndex,
}: ParentRowProps) => {
  const [selected, setSelected] = React.useState([]);

  const setAccordion = (index: string) => {
    const actualItems = [...selected];
    if (actualItems.includes(index)) {
      setSelected(actualItems.filter((item: string) => item !== index));
    } else {
      actualItems.push(index);
      setSelected(actualItems);
    }
  };
  return (
    <div
      css={style.parentRows}
      style={{
        borderTopLeftRadius: isFirst ? 8 : 0,
        borderTopRightRadius: isFirst ? 8 : 0,
        borderBottomLeftRadius: isLast ? 8 : 0,
        borderBottomRightRadius: isLast ? 8 : 0,
      }}
    >
      <div
        css={style.parentRow}
        onClick={() => setAccordion(index)}
        style={{
          borderTopLeftRadius: isFirst ? 8 : 0,
          borderTopRightRadius: isFirst ? 8 : 0,
          borderBottomLeftRadius: isLast ? 8 : 0,
          borderBottomRightRadius: isLast ? 8 : 0,
        }}
      >
        <div css={style.parentRowLeft}>
          <div css={style.icon}>
            <Icon
              icon={selected.includes(index) ? "chevron-up" : "chevron-down"}
            />
          </div>
          <div css={style.buttomLabel}>{label}</div>
        </div>
        <div>
          <div css={style.value}>
            <Icon icon={"search"} /> {total}
          </div>
        </div>
      </div>
      <Collapse isOpened={Boolean(selected.includes(index) && child)}>
        {child.map((children, idx): any => (
          <div css={style.childRow} key={`item-${idx}`}>
            <div
              css={style.child}
              onClick={() => setAccordion(`${index}-${idx}`)}
            >
              <div css={style.parentRowLeft}>
                <div css={style.icon}></div>
                <div css={style.parentContainer}>
                  <div css={style.label}>{children.label}</div>
                  <div css={style.description}>{children.description}</div>
                </div>
              </div>
              <a css={style.value} href={children.action}>
                <Icon icon={"search"} />{renderData(lgxData, parentIndex, idx)}
              </a>
            </div>
            {selected.includes(`${index}-${idx}`) &&
              children &&
              children.data &&
              children.data.map((children, idx2): any => (
                <div css={style.full}>
                  <div
                    css={children.data ? style.childRowLarge : style.subChild}
                    key={`item-2-${idx2}`}
                    onClick={() => setAccordion(`${index}-${idx}-${idx2}`)}
                  >
                    <div css={style.parentRowLeft}>
                      <div css={style.quadratin} />
                      {children.data && (
                        <Icon
                          icon={
                            selected.includes(`${index}-${idx}-${idx2}`)
                              ? "chevron-up"
                              : "chevron-down"
                          }
                        />
                      )}
                      <div>{children.label}</div>
                    </div>
                    <div>
                      <div css={style.value}>
                        <Icon icon={"search"} />
                      </div>
                    </div>
                  </div>
                  {selected.includes(`${index}-${idx}-${idx2}`) &&
                    children &&
                    children.data &&
                    children.data.map((children, idx2): any => (
                      <div>
                        <div
                          css={
                            children.data ? style.childRowLarge : style.subChild
                          }
                          key={`item-2-${idx2}`}
                        >
                          <div css={style.parentRowLeft}>
                            <div css={style.quadratin} />
                            <div>{children.label}</div>
                          </div>
                          <div>
                            <div css={style.value}>
                              <Icon icon={"search"} /> {children.value}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </Collapse>
    </div>
  );
};

const SustainableTabs: React.FunctionComponent<SustainableTabsProps> = ({
  data,
  lgxData,
  total,
  index,
}: SustainableTabsProps) => {


  return (
    <div css={style.sustainableTabs}>
      <div css={style.container}>
        {data &&
          data.elements &&
          data.elements.map((elem: any, idx: number): any => (
            <ParentRow
              parentIndex={index}
              total={total}
              lgxData={lgxData}
              key={`row-${idx}`}
              label={elem.label}
              value={(elem.data || [])
                .reduce((acc: any, a: any) => acc + Number(a.value), 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              letter={" "}
              child={elem.data}
              isFirst={idx === 0}
              isLast={idx === data.elements.length - 1}
              index={String(idx)}
            />
          ))}
      </div>
    </div>
  );
};

export default SustainableTabs;
