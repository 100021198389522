/** @jsx jsx */

import * as React from 'react';
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';
import { SitecoreGenericTextField } from '../../interfaces';
import { fetchData, getDataWithChild } from '../../utils/helper';
import { breakpoint, breakpointMax, breakpointMinMobile } from '../../style';
import { Button, Icon, Loader } from '@bdl-cmn-shared-packages-npm/design-system';
import hexa from './hexa.svg';
import { canUseDOM } from '../../predicates';

export interface LatestPressReleasesProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const LatestPressReleases: React.FunctionComponent<LatestPressReleasesProps> = (props: LatestPressReleasesProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [press, setPress] = React.useState<any>(null);
    const [isLoadingPress, setIsLoadingPress] = React.useState<boolean>(true);

    const fetchPress = async (id: string) => {
        try {
          const result: any = await fetchData(id);
          const dataReq = await getDataWithChild(result);
      
          if (dataReq && dataReq.length > 0) {
            const sortedData = dataReq.map((item: any) => {
              if (item && item.children && item.children.length > 0) {
                item.children.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
              }
              return item;
            });
      
            const latestItems = [];
            for (const item of sortedData) {
              if (item.children) {
                latestItems.push(...item.children.slice(0, 5)); // Add latest 5 from each child array
              } else {
                latestItems.push(item); // Add top-level items if no children
              }
            }
            const finalPressReleases = latestItems.slice(0, 5); // Get the overall latest 5
      
            setPress(finalPressReleases);
            setIsLoadingPress(false);
          }
        } catch (err) {
          console.error(err);
        }
      };
      
    
    React.useEffect(() => {
        fetchPress("A8941707-2723-4AF1-A140-A019FF340E1C")
    }, []);


    const parentContainer = css`
        width: 100%;
    `;

    const container = css`
        width: 100%;
        padding: 80px;
        padding-bottom: 80px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        @media (max-width: ${breakpointMax}) {
            padding: 16px;
            padding-bottom: 30px;
        }
    `;

    const mainTitle = css`
        color: #253845;
        font-weight: 700;
        font-size: 36px;
        line-height: normal;
        @media (max-width: ${breakpointMax}) {
            font-size: 32px;
        }
    `;

    const cards = css`
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 24px;
        @media (max-width: ${breakpointMax}) {
            flex-direction: row;
        }
    `;

    const card = css`
        width: 600px;
        min-width: 600px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 24px;
        margin-top: 24px;
        border-radius: 8px;
        border: 1px solid #E1E3E5;
        background: #FFF;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: ${breakpointMax}) {
            width: 90%;
            min-width: 90%;
            height: 350px;
        }

        @media (min-width: ${breakpointMax}) and (max-width: ${breakpoint}) {
            min-width: 90%;
            height: 280px;  
        }
        @media (max-width: ${breakpointMinMobile}) {
            padding: 18px;
        }
    `;

    const header = css`
        display: flex;
        width: 100%;
        margin-left: auto;
    `;

    const icon = css`
        background-image: url(${hexa});
        background-position: center center;
        margin-right: 0px;
        min-height: 50px;
        min-width: 50px;
        align-items: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #008C3D;
    `;

    const title = css`
        color: #425563;
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
        margin-left: 24px;
        @media (max-width: ${breakpointMax}) {
            font-size: 18px;
            margin-left: 21px;
        }
    `;

    const shortDescription = css`
        color: #253845;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top: 15px;
        align-self: flex-start;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: ${breakpointMax}) {
            font-size: 14px;
            max-height: 75%;
            line-height: 137%;
        }


    `;

    const date = css`
        color: #425563;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-top: 10px;
        @media (max-width: ${breakpointMax}) {
            font-size: 13px;
        }
    `;

    const dateSection = css`
        align-self: flex-start;
    `;

    const button = css`
        margin-top: 25px;
        width: 100%;
        display: flex;
    `;

    const buttonLabel: string = getFieldValue<string>(fields, "buttonLabel", "");
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");

    return (
        <div css={parentContainer}>
            <div css={container}>

                {fields && fields.title && (fields.title as any).value && (
                    <div css={mainTitle}>
                        <Text
                            field={fields && (fields.title as SitecoreGenericTextField)}
                        />
                    </div>
                )}
                <Loader loading={isLoadingPress} centered css={{ minHeight: 400 }}>
                    <div css={cards}>
                        {press && press.length > 0 ? (
                            press.map((data: any, index: any) => (
                                <div key={index} css={card} onClick={() => {
                                    canUseDOM && data.newLayout === "1"
                                        ? window.open(`/about-us/press-centre/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`, '_blank')
                                        : canUseDOM && window.open(`/about-us/press-centre/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`, '_blank')
                                }}>
                                    <div css={header}>
                                        <div css={icon}>
                                            <Icon icon={"bullhorn"} />
                                        </div>
                                        <div css={title}>
                                            {data.Title}
                                        </div>
                                    </div>
                                    <div css={shortDescription}>
                                        <div dangerouslySetInnerHTML={{ __html: data['Short description'] }} />
                                    </div>
                                    <div css={dateSection}>
                                        <div css={date}>
                                            {data.Label}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>
                </Loader>
                
                <div css={button}>
                    {buttonLabel && (
                        <div>
                            <Link
                                target={(fields['buttonLink']?.value?.linktype === "external" ? "_blank" : "_self")}
                                field={fields['buttonLink']}
                                css={{ textDecoration: 'none' }}
                            >
                                <Button
                                    label={buttonLabel}
                                    variant={"thirdary"}
                                    iconRight={"chevron-right"}
                                    style={{
                                        boxShadow: 'none',
                                        border: '1px solid rgba(34, 170, 95, 0.50)',
                                        borderRadius: '4px',
                                    }}
                                />
                            </Link>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}
