/** @jsx jsx */

import {
  Button,
  Icon,
  Td,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";

import { DocumentRow } from "../document-row";
import { Information } from "../information";
import Loader from "../../../Loader/loader.component";
import { OAMResultsDeposit } from "../../useFilters";
import { Published } from "../published";
import React from "react";
import { formatDate } from "../../../../../utils/date";
import { style } from "./index.style";
import useDetails from "./useDetails";
import { useMemo } from "react";
import { OAMCategoryLabel } from "../../../../../enums/oam-category";

export interface DepositRowProps {
  deposit: OAMResultsDeposit;
  lang: string;
}

export const DepositRow = ({ deposit, lang}: DepositRowProps) => {
  const { open, onToggle, loading, details } = useDetails(deposit);
  const initials = useMemo(
    () =>
      deposit.libTypeDepo
        .split(" ")
        .slice(0, 2)
        .map((w) => w[0].toUpperCase())
        .join(""),
    [deposit.libTypeDepo]
  );

  return (
    <>
      <Tr css={[style.container, open && style.openRow]} onRowClick={onToggle}>
        <Td>
          <Published status={deposit.listeActionDepo} />
        </Td>
        <Td>
          <Information type={deposit.libTypeDepo} />
        </Td>
        <Td>{deposit.libLgTiersDecla}</Td>
        <Td>
          <span css={style.bold}>{deposit.idSoumis}</span>
        </Td>
        <Td>{formatDate(deposit.dtPubli, "DD/MM/YYYY HH:mm:ss")}</Td>
        <Td>
          {formatDate(deposit.dtDebPerRef)}&nbsp;-&nbsp;
          {formatDate(deposit.dtFinPerRef)}
        </Td>
        <Td>
          <div css={[style.chevron, open && style.openChevron]}>
            <Icon icon="chevron-down" />
          </div>
        </Td>
      </Tr>

      {open &&
        (loading ? (
          <Tr>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Loader loading={true}>
              <></>
            </Loader>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        ) : (
          <>
            {details?.documents.map((document, index) => (
              <DocumentRow
                document={document}
                isFirstRow={index === 0}
                isSecondLevel={false}
              />
            )) || null}

            {details?.events.map((event, index) => (
              <>
                <Tr
                  key={`${event.dtPubli}-${event.eventCode}`}
                  css={[
                    style.subRow,
                    details?.documents.length === 0 &&
                      index === 0 &&
                      style.firstSubRow,
                  ]}
                >
                  <Td>
                    <div css={style.clock}>
                      <Icon icon="clock" />
                    </div>
                  </Td>
                  <Td>
                    <div css={[style.data, style.level2]}>
                      <span>Date</span>
                      <span>
                        {formatDate(event.dtPubli, "DD/MM/YYYY HH:mm:ss")}
                      </span>
                    </div>
                  </Td>
                  <Td>
                    <div css={style.data}>
                      <span>Category</span>
                      <span>{OAMCategoryLabel(event.eventCode, "")}</span>
                    </div>
                  </Td>
                  <Td>
                    <div css={style.data}>
                      <span>Previous value</span>
                      <span>{event.oldValueStart || "-"}</span>
                    </div>
                  </Td>
                  <Td colSpan={2}>
                    <div css={style.data}>
                      <span>New value</span>
                      <span>{event.newValueStart || "-"}</span>
                    </div>
                  </Td>
                  <Td></Td>
                </Tr>

                {event.attachedDocs?.map((document) => (
                  <DocumentRow
                    document={document}
                    isSecondLevel={true}
                    isFirstRow={false}
                  />
                )) || null}
              </>
            )) || null}
          </>
        ))}
    </>
  );
};
