export enum StandardAndPrinciple {
	ICMA_GREENBOND = 'ICMA - Green Bond Principles',
	ICMA_SOCIALBOND = 'ICMA - Social Bond Principles',
	ICMA_SUSTAINABILITYBOND = 'ICMA - Sustainability Bond Guidelines',
	ICMA_SLB = 'ICMA - Sustainability-Linked Bond Principles',
	LMA = 'LMA - Green Loan Principles',
	CBI = 'CBI - Climate Bonds Standard',
	EU_GBS = '	EU Green Bond Standards (draft version)',
	EU_TAXONOMY = 'EU Taxonomy (draft version)',
	PBOC = 'PBOC - Climate Bond Endorsed Project Catalogue',
	NDRC = 'NDRC - Guidance for the Issue of Green Bonds ',
	ASEAN = 'ASEAN – Green Bond Standards',
	OWN_FRAMEWORK = '-',
	OTHER = '-',
}

export function standardAndPrincipleLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(StandardAndPrinciple).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(StandardAndPrinciple)[index] : value;
	}catch (e){
		console.error("Failed to get StandardAndPrinciple label for '" + value + "'", e);
	}
		
	return value;
}
