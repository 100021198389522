/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";
import { useReadingTime } from "react-reading-time-estimator";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, renderLink } from "../../utils/helper";
import { canUseDOM } from "../../predicates";
import SocialSharing from "../../core/components/SocialSharing/socialSharing.component";
import { breakpointMax } from "../../style";
import { formatDate } from "../../utils/date";

export interface BlogArticleComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;

  h1 {
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    color: #425563;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      color: #425563;
    }
  }

  h2 {
    color: #253845;
  }

  h3 {
    color: #425563;
  }

  p {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const imageContainer = (total: number) => css`
  width: ${100 / total}%;
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
  margin-top: 32px;
  margin-bottom: 32px;
`;
const imageItem = css`
  max-width: 100%;
  width: 100%;
  border-radius: 16px;
`;
const DesktopInline = (last = false) => css`
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;
const rightPart = (last = false) => css`
  display: flex;
  align-items: center;
  //flex-shrink: 0;

  @media (max-width: ${breakpointMax}) {
    border-bottom: ${last ? "none" : "solid 1px #D9DEE2"};
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;
const inline = (last = false) => css`
  display: flex;
  align-items: center;

  a {
    &:hover {
      text-decoration: none;
      color: #1a844a;
    }
  }

  @media (max-width: ${breakpointMax}) {
    border-bottom: ${last ? "none" : "solid 1px #D9DEE2"};
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;
const hideMobile = css`
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const author = css`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpointMax}) {
    border: 1px solid #d9dee2;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const articleText = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }
`;
const avatar = () => css`
  display: flex;
  height: 32px;
  margin-right: 4px;

  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
    height: 32px;
    width: 28px;
  }
`;

const authorText = css`
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #425563;
  justify-content: space-between;

  a {
    color: #22aa5f;
    text-decoration: underline;
  }

  span {
    margin-right: 4px;
    margin-left: 4px;
  }

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const BlogArticleComponent: React.FunctionComponent<BlogArticleComponentProps> =
  (props: BlogArticleComponentProps): React.ReactElement => {
    const { fields } = props;
    const location = useLocation();
    const cat = location.pathname.split("/")?.[2];
    const [fullText, setFullText] = React.useState<string>("");
    const image: any = getFieldValue<any>(fields, "main image", null);
    const { text } = useReadingTime(fullText || "");
    /*const [response, setResponse] = useState<any>(null);*/
    React.useEffect(() => {
      const words = canUseDOM && document.getElementById("articleContainer");
      setFullText(words?.outerHTML?.replace(/(<([^>]+)>)/gi, ""));
    }, [canUseDOM]);

    return (
      <article css={container}>
        <h1>
          <Text
            field={fields && (fields["title"] as SitecoreGenericTextField)}
          />
        </h1>
        <div css={author}>
          {fields?.author && (
            <>
              <div css={authorText}>
                <div css={DesktopInline}>
                  <div css={inline(false)}>
                    <div css={avatar}>
                      <img
                        src={renderLink(
                          fields?.author?.fields?.Picture?.value?.src
                        )}
                        alt={"author"}
                      />
                    </div>
                    <a
                      href={`/blog/authors/${(fields?.author?.name).replace(
                        " ",
                        "-"
                      )}`}
                    >
                      {fields?.author?.name}
                    </a>
                  </div>
                  {cat && (
                    <div css={inline(false)}>
                      <span>in</span>
                      <a href={`/blog/${cat}`}>
                        {capitalizeFirstLetter(cat?.replace(/-/g, " "))}
                      </a>
                    </div>
                  )}

                  <div css={inline(false)}>
                    <span css={hideMobile}>•</span>
                    {formatDate(fields["Creation Date"]?.value, "DD MMMM YYYY")}
                    <span>•</span>
                    {text}
                  </div>
                </div>
                <div css={rightPart}>
                  <SocialSharing
                    url={
                      canUseDOM &&
                      window.location.href.replace("localhost", "luxse.com")
                    }
                    quote={fields?.title?.value}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          id="imageStart"
          css={imageContainer(1)}
          onClick={() =>
            canUseDOM && console.log(renderLink(image.url), "_blank")
          }
        >
          <img css={imageItem} src={renderLink(image?.src)} alt={image?.alt} />
        </div>
        <div css={articleText}>
          <RichText
            field={fields && (fields["content"] as SitecoreGenericTextField)}
          />
        </div>
      </article>
    );
  };
