import moment from 'moment-timezone';
/** @jsx jsx */
import { jsx } from "@emotion/react";
import {EMPTY_DATA_PLACEHOLDER} from "../core/constants";

/** @jsx jsx */

const ZONE_NAME = "Europe/Luxembourg";
const UTC_OFFSET = '+0100';

export const isValidDate= (date: any) => {
  return date && moment(date)?.isValid();
}

export const getCurrentMoment = () => {
  let now = moment();
  return now?.tz(ZONE_NAME);
}

export const getStartOfDay = () => {
  return getCurrentMoment().startOf("day");
}

export const getEndOfDay = () => {
  return getCurrentMoment().endOf("day");
}

export const parseToMoment = (date: any) => {
  if( isValidDate(date) ) {
    let mom = moment(date);
    return mom?.tz(ZONE_NAME);
  }else
    return null;
}

export const parseToMomentFormat = (date: any, format: string) => {
  if( isValidDate(date) ) {
    let mom = moment(date, format);
    return mom?.tz(ZONE_NAME);
  }else
    return null;
}

export const formatDate = (date: any, format = "DD/MM/YYYY") => {
  return isValidDate(date)? parseToMoment(date)?.format(format)
    : EMPTY_DATA_PLACEHOLDER
}
export const formatDateTime = (date: any, format = "HH:mm:ss") => {
  return isValidDate(date) ? parseToMoment(date)?.format(format)
    : EMPTY_DATA_PLACEHOLDER
}