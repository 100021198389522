export const breakpoint: string = "769px";
export const breakpointMax: string = "768px";


export const breakpointMaxMobile: string = "500px";
export const breakpointMinMobile: string = "321px";
export const breakpointMobileSmall: string = "320px";


export const breakpointMarketCap: string = "1075px";
export const breakpointMarketCapMax: string = "1074px";

export const breakpointDesktopSmall: string = "1024px";
export const breakpointDesktopSmallMin: string = "1023px";


export const breakpointChart: string = "1483px";
export const breakpointChartMax: string = "1484px";


export const breakpointDesktopLargest: string = "2560px";